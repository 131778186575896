import { useEffect, useState } from 'react'

import { Popover } from '@mui/material'
import { captureException } from '@sentry/browser'
import { GRAPHQL_ERROR_CODES } from 'api/src/common/enums'
import type {
  DuplicateBaserowBase,
  DuplicateBaserowBaseVariables,
  CreateBaserowBase,
  CreateBaserowBaseVariables,
  DeleteBaserowBase,
  DeleteBaserowBaseVariables,
  FindWorkspaceListQuery,
  FindWorkspaceListQueryVariables,
  UpdateBaserowBaseName,
  UpdateBaserowBaseNameVariables,
  PollDuplicatingBaserowBases,
  PollDuplicatingBaserowBasesVariables,
  UpsertBaserowCardAppearance,
  UpsertBaserowCardAppearanceInput,
  UpsertBaserowCardAppearanceVariables,
  BaserowWorkspace,
  BaserowApplication,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import LoadingComponent from 'src/components/Library/Loading'
import { useAuth } from 'src/Providers'

import ToastMessage from '../ToastMessage/ToastMessage'

import BasesScreenHeader from './BasesScreenHeader'
import useBasesScreenStore from './BasesScreenStore'
import BaseStyleEditor from './BaseStyleEditor'
import EmptyBasesScreen from './EmptyBasesScreen'
import {
  DUPLICATE_BASEROW_BASE,
  CREATE_BASEROW_BASE,
  DELETE_BASEROW_BASE,
  FIND_WORKSPACE_LIST_QUERY,
  POLL_DUPLICATING_BASEROW_BASES,
  UPDATE_BASEROW_BASE_NAME,
  UPSERT_BASE_CARD_APPEARANCE,
} from './graphql'
import { baseDuplicatedPattern } from './utils'
import WorkspacesList from './WorkspacesList'

const BasesScreen = () => {
  const [
    editBaseId,
    setEditBaseId,
    setBaseIdRenameMode,
    baseStyleEditorAnchor,
    setBaseStyleEditorAnchor,
    setCardsUpdating,
    setCreateBaseLoading,
    setCanEditCardAppearance,
    duplicatingBase,
    setDuplicatingBase,
  ] = useBasesScreenStore((state) => [
    state.editBaseId,
    state.setEditBaseId,
    state.setBaseIdRenameMode,
    state.baseStyleEditorAnchor,
    state.setBaseStyleEditorAnchor,
    state.setCardsUpdating,
    state.setCreateBaseLoading,
    state.setCanEditCardAppearance,
    state.duplicatingBase,
    state.setDuplicatingBase,
  ])

  const [shouldPoll, setShouldPoll] = useState(false)

  const { hasRole } = useAuth()

  const canEditCardAppearance = hasRole(['ADMIN', 'OWNER', 'EDITOR'])

  const [upsertBaserowCardAppearance] = useMutation<
    UpsertBaserowCardAppearance,
    UpsertBaserowCardAppearanceVariables
  >(UPSERT_BASE_CARD_APPEARANCE, {
    onCompleted: () => {
      toast.success('Base appearance updated successfully')
    },
    onError: () => {
      toast.error(
        <ToastMessage
          message="There was an error updating your base appearance"
          description={'Please try again or reload the page.'}
        />,
        {
          duration: 5000,
        },
      )
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindWorkspaceListQuery'],
  })

  const onBaseAppearanceUpsert = async (
    input: UpsertBaserowCardAppearanceInput,
  ) => {
    setCardsUpdating((prev) => [...prev, input.applicationId])
    await upsertBaserowCardAppearance({
      variables: {
        input,
      },
    })
    setCardsUpdating((prev) => prev.filter((id) => id !== input.applicationId))
    setBaseStyleEditorAnchor(null)
  }

  const [createBase, { loading: createBaseLoading }] = useMutation<
    CreateBaserowBase,
    CreateBaserowBaseVariables
  >(CREATE_BASEROW_BASE, {
    onCompleted: (data) => {
      setBaseIdRenameMode(data.createBaserowApplication)
      toast.success('Base created successfully')
    },
    onError: () => {
      toast.error(
        <ToastMessage
          message="There was an error creating your base"
          description={'Please try again or reload the page.'}
        />,
        {
          duration: 5000,
        },
      )
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindWorkspaceListQuery'],
  })

  const onCreateBase = async (workspaceId: number) => {
    await createBase({
      variables: {
        input: { workspaceId },
      },
    })
  }

  const [deleteBase] = useMutation<
    DeleteBaserowBase,
    DeleteBaserowBaseVariables
  >(DELETE_BASEROW_BASE, {
    onCompleted: () => {
      toast.success('Base deleted successfully')
    },
    onError: () => {
      toast.error(
        <ToastMessage
          message="There was an error deleting your base"
          description={'Please try again or reload the page.'}
        />,
        {
          duration: 5000,
        },
      )
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindWorkspaceListQuery'],
  })

  const onDeleteBase = async (baseId: number) => {
    setCardsUpdating((prev) => [...prev, baseId])
    await deleteBase({
      variables: {
        input: { id: baseId },
      },
    })
    setCardsUpdating((prev) => prev.filter((id) => id !== baseId))
  }

  const [updateBaseName] = useMutation<
    UpdateBaserowBaseName,
    UpdateBaserowBaseNameVariables
  >(UPDATE_BASEROW_BASE_NAME, {
    onCompleted: () => {
      toast.success('Base name updated successfully')
    },
    onError: () => {
      toast.error(
        <ToastMessage
          message="There was an error updating the base name"
          description={'Please try again or reload the page.'}
        />,
        {
          duration: 5000,
        },
      )
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindWorkspaceListQuery'],
  })

  const onUpdateBaseName = async (baseId: number, name: string) => {
    setCardsUpdating((prev) => [...prev, baseId])
    await updateBaseName({
      variables: {
        input: { id: baseId, name },
      },
    })
    setCardsUpdating((prev) => prev.filter((id) => id !== baseId))
  }

  const [duplicateBase] = useMutation<
    DuplicateBaserowBase,
    DuplicateBaserowBaseVariables
  >(DUPLICATE_BASEROW_BASE, {
    onError: (error) => {
      const errorCode = error?.graphQLErrors?.[0]?.extensions?.code
      if (errorCode === GRAPHQL_ERROR_CODES.MAX_JOB_COUNT_EXCEEDED) {
        toast.error(
          'You have reached the maximum number of duplications allowed at once. Please try again later.',
          {
            duration: 5000,
          },
        )
      } else {
        toast.error('There was an error duplicating your base', {
          duration: 5000,
        })
      }
    },
    update: (_, { data: { duplicateBaserowApplication } }) => {
      toast.success('Base duplicating')

      setDuplicatingBase(duplicateBaserowApplication)
    },
  })

  const onDuplicateBase = async (baseId: number) => {
    await duplicateBase({
      variables: {
        input: { applicationId: baseId },
      },
    })
  }

  const { client } = useQuery<
    PollDuplicatingBaserowBases,
    PollDuplicatingBaserowBasesVariables
  >(POLL_DUPLICATING_BASEROW_BASES, {
    skip: !shouldPoll,
    pollInterval: 3000,
    // disable the automatic cache update and use our own logic.
    // This is because the generic cache update causes the duplicating card to ->
    // momentarily disappear between when the polling is completed and when the refetch finishes.
    fetchPolicy: 'no-cache',
    onCompleted: async ({ duplicatingBaserowApplication }) => {
      if (!duplicatingBaserowApplication) {
        setShouldPoll(false)

        // we need to do a refetch when we have finished polling ->
        // this is because we don't know the new id of the duplicated base
        const refetch = await client.query<FindWorkspaceListQuery>({
          query: FIND_WORKSPACE_LIST_QUERY,
        })

        if (duplicatingBase?.originalApplicationId === editBaseId) {
          // if we haven't started to edit a different base ->
          // we can have the new duplicated base pop in to edit mode straight away

          const duplicatedWorkspace = refetch.data.baserowWorkspaces.find(
            (workspace: BaserowWorkspace) =>
              workspace.baserowWorkspaceId === duplicatingBase.workspaceId,
          )

          const duplicatedBases = duplicatedWorkspace.applications.filter(
            (application: BaserowApplication) =>
              baseDuplicatedPattern(
                duplicatingBase.originalApplicationName,
              ).test(application.name) &&
              application.id !== duplicatingBase.originalApplicationId,
          )

          // if there are multiple matching, pick the highest id as this is the most recent
          const duplicatedBase = duplicatedBases.reduce(
            (acc: BaserowApplication | null, base: BaserowApplication) => {
              if (!acc) return base
              if (base.id > acc.id) return base
              return acc
            },
            null,
          )

          setEditBaseId(duplicatedBase?.id)
          setBaseIdRenameMode(duplicatedBase?.id)
        }
        setDuplicatingBase(null)
        toast.success('Base duplication complete')
        return
      }
      setDuplicatingBase(duplicatingBaserowApplication)
    },
    onError: (e) => {
      toast.error(
        <ToastMessage
          message="There was an error duplicating your base"
          description={'Please try again or reload the page.'}
        />,
        {
          duration: 5000,
        },
      )
      captureException(e)
    },
  })

  useEffect(() => {
    setCreateBaseLoading(createBaseLoading)
  }, [createBaseLoading, setCreateBaseLoading])

  useEffect(() => {
    setCanEditCardAppearance(canEditCardAppearance)
  }, [canEditCardAppearance, setCanEditCardAppearance])

  const { data, loading, error } = useQuery<
    FindWorkspaceListQuery,
    FindWorkspaceListQueryVariables
  >(FIND_WORKSPACE_LIST_QUERY, {
    onCompleted: (data) => {
      setDuplicatingBase(data?.duplicatingBaserowApplication)

      // if a workspace has null permissions it is an error but we just give them
      // no permissions so the page doesn't crash
      const workspacesWithNullPermissions = data?.baserowWorkspaces.filter(
        (workspace) => !workspace.applicationPermissions,
      )

      if (workspacesWithNullPermissions.length === 0) return

      const workspaceNames = workspacesWithNullPermissions.map(
        (workspace) => workspace.workspaceName,
      )

      if (workspaceNames.length === 0) return

      toast.error(
        <ToastMessage
          message="There was an error loading your bases"
          description={`Can't find permissions for workspace${workspaceNames.length > 1 ? 's' : ''}: ${workspaceNames.join(
            ', ',
          )}. You will not be able to create, update, or delete bases within ${workspaceNames.length > 1 ? 'these workspaces' : 'this workspace'}.`}
        />,
        {
          duration: 12_000,
        },
      )
    },
  })

  useEffect(() => {
    setShouldPoll(!!duplicatingBase)
  }, [duplicatingBase, data])

  if (!data && loading) {
    // if we already have data and are just loading because of a refetch
    // we can just show the data we have and let the refetch happen in the background
    return (
      <div className="h-[400px]">
        <LoadingComponent />
      </div>
    )
  }
  if (error) {
    return <div style={{ color: 'red' }}>Error: {error.message}</div>
  }

  if (!data || !data.baserowWorkspaces || data.baserowWorkspaces.length === 0) {
    captureException(
      'User on bases page with no matching baserowWorkspaces in the database',
    )
    return <EmptyBasesScreen noWorkspacesFound={true} />
  }

  const workspacesUserHasAccessTo = data?.baserowWorkspaces.filter(
    // if the resolver for applications returns null then we don't have access to the workspace
    (workspace) => workspace?.applications,
  )

  const workspacesWithBases = workspacesUserHasAccessTo.filter(
    (workspace) => workspace.applications.length > 0,
  )

  const workspacesUserCanCreateBasesIn = workspacesUserHasAccessTo.filter(
    (workspace) => workspace?.applicationPermissions?.createPermission?.default,
  )

  if (workspacesWithBases.length === 0) {
    return (
      <EmptyBasesScreen
        noWorkspacesFound={false}
        workspaces={workspacesUserCanCreateBasesIn}
        onCreateBase={onCreateBase}
      />
    )
  }

  const editBase = workspacesWithBases
    .flatMap((workspace) => workspace.applications)
    .find((application) => application.id === editBaseId)

  return (
    <>
      <BasesScreenHeader
        workspaces={workspacesUserCanCreateBasesIn}
        onCreateBase={onCreateBase}
      />
      <WorkspacesList
        workspaces={workspacesWithBases}
        onDeleteBase={onDeleteBase}
        onUpdateBaseName={onUpdateBaseName}
        onDuplicateBase={onDuplicateBase}
      />
      <Popover
        open={Boolean(baseStyleEditorAnchor)}
        anchorEl={baseStyleEditorAnchor}
        onClose={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation()
          e.preventDefault()
          setBaseStyleEditorAnchor(null)
        }}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <BaseStyleEditor base={editBase} onSave={onBaseAppearanceUpsert} />
      </Popover>
    </>
  )
}

export default BasesScreen
