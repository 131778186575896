import { type FC } from 'react'

import {
  DocumentDuplicateIcon,
  PaintBrushIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Divider, Menu, MenuItem } from '@mui/material'
import type { ApplicationPermissions } from 'types/graphql'

import { useConfirm } from 'src/lib/hooks/Confirmation'

import useBasesScreenStore from './BasesScreenStore'
import type { BaserowApplication } from './types'
import { baseHasPermission } from './utils'

interface BaseCardEditMenuProps {
  handleMenuClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  handleOpenColorPicker: (event: React.MouseEvent<HTMLLIElement>) => void
  menuAnchorEl: HTMLElement | null
  onDeleteBase: (baseId: number) => void
  onDuplicateBase: (baseId: number) => void
  base: BaserowApplication
  setRenameMode: (renameMode: boolean) => void
  applicationPermissions: ApplicationPermissions
}

const BaseCardEditMenu: FC<BaseCardEditMenuProps> = ({
  handleMenuClose,
  handleOpenColorPicker,
  menuAnchorEl,
  onDeleteBase,
  onDuplicateBase,
  base,
  setRenameMode,
  applicationPermissions,
}) => {
  const [canEditCardAppearance] = useBasesScreenStore((state) => [
    state.canEditCardAppearance,
  ])

  const menuOpen = Boolean(menuAnchorEl)

  const confirmDeleteBase = useConfirm()

  const { updatePermission, duplicatePermission, deletePermission } =
    applicationPermissions

  return (
    <Menu
      open={menuOpen}
      anchorEl={menuAnchorEl}
      onClose={handleMenuClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {baseHasPermission(updatePermission, base.id) && (
        <MenuItem
          className="flex gap-2 text-sm text-gray-500"
          onClick={(e) => {
            setRenameMode(true)
            handleMenuClose(e)
          }}
        >
          <PencilIcon className="h-4 w-4 text-gray-500" />
          <p>Rename Base</p>
        </MenuItem>
      )}
      {baseHasPermission(duplicatePermission, base.id) && (
        <MenuItem
          className="flex gap-2 text-sm text-gray-500"
          onClick={(e) => {
            onDuplicateBase(base.id)
            handleMenuClose(e)
          }}
        >
          <DocumentDuplicateIcon className="h-4 w-4 text-gray-500" />
          <p data-testid="duplicate-base-menu-item">Duplicate Base</p>
        </MenuItem>
      )}

      {canEditCardAppearance && (
        <MenuItem
          className="flex gap-2 text-sm text-gray-500"
          onClick={handleOpenColorPicker}
        >
          <PaintBrushIcon className="h-4 w-4 text-gray-500" />
          <p data-testid="customise-appearance-menu-item">
            Customise Appearance
          </p>
        </MenuItem>
      )}

      {baseHasPermission(deletePermission, base.id) && (
        // using an empty div instead of a fragment here to work around console error ->
        // 'MUI: The Menu component doesn't accept a Fragment as a child.'
        <div>
          <Divider />

          <MenuItem
            className={'flex gap-2 text-sm text-red-500'}
            onClick={(e) => {
              confirmDeleteBase({
                title: `Are you sure you want to delete ${base.name}?`,
                description:
                  'Once a base is deleted, it may not be possible to restore.',
                confirmationText: 'Delete Base',
                confirmationButtonProps: {
                  color: 'error',
                  className: 'bg-red-500',
                },
                titleProps: {
                  sx: { color: 'red' },
                },
              }).then((isConfirmed) => {
                if (!isConfirmed) return
                onDeleteBase(base.id)
                handleMenuClose(e)
              })
            }}
          >
            <TrashIcon className="h-4 w-4 text-red-500" />
            <p data-testid="delete-base-menu-item">Delete Base</p>
          </MenuItem>
        </div>
      )}
    </Menu>
  )
}

export default BaseCardEditMenu
