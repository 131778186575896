import type { FindWorkspaceListQuery } from 'types/graphql'

export type BaserowWorkspace = FindWorkspaceListQuery['baserowWorkspaces'][0]

export type BaserowApplication = BaserowWorkspace['applications'][0]

export enum CardHistoryTag {
  OPENED_TODAY = 'Opened Today',
  OPENED_YESTERDAY = 'Opened Yesterday',
  OPENED_LAST_WEEK = 'Opened Last Week',
  OPENED_LAST_MONTH = 'Opened Last Month',
  NOT_OPENED_RECENTLY = 'Not Opened Recently',
  NEVER_OPENED = '',
}

export interface CardClickHistoryItem {
  baseId: number
  clickedAt: string
}

export enum BaseStyleEditorTab {
  COLOUR = 'Colour',
  ICON = 'Icon',
}
