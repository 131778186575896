import { useEffect, useState, type FC } from 'react'

import { CircleStackIcon } from '@heroicons/react/24/outline'
import type { DuplicatingBaserowApplication } from 'types/graphql'
import { useLocalStorage } from 'usehooks-ts'

import Empty from 'src/components/Library/Empty/Empty'
import { useAuth } from 'src/Providers'

import useBasesScreenStore from './BasesScreenStore'
import type { BaserowWorkspace, CardClickHistoryItem } from './types'
import WorkspaceCard from './WorkspaceCard'

interface WorkspacesListProps {
  workspaces: BaserowWorkspace[]
  onDeleteBase: (baseId: number) => void
  onUpdateBaseName: (baseId: number, name: string) => void
  onDuplicateBase: (baseId: number) => void
}

const WorkspacesList: FC<WorkspacesListProps> = ({
  workspaces,
  onDeleteBase,
  onUpdateBaseName,
  onDuplicateBase,
}) => {
  const [duplicatingBase, searchValue] = useBasesScreenStore((state) => [
    state.duplicatingBase,
    state.searchValue,
  ])

  const [cardClickHistoryMap, setCardClickHistoryMap] = useState<
    Map<number, string>
  >(new Map())

  const [searchWorkspaces, setSearchWorkspaces] =
    useState<BaserowWorkspace[]>(workspaces)

  const { currentUser } = useAuth()
  const [cardClickHistory, setCardClickHistory] = useLocalStorage<
    CardClickHistoryItem[]
  >(`baserowCardClickHistory-memberId-${currentUser.membershipData?.id}`, [])

  useEffect(() => {
    setCardClickHistoryMap(
      new Map(cardClickHistory.map((h) => [h.baseId, h.clickedAt])),
    )
    // note that we intentionally don't include cardClickHistory in the deps array
    // this because we only want the bases to re-order on page load (not every time we click on a card)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSearchWorkspaces(
      workspaces
        .map((workspace) => {
          const filteredApplications = workspace.applications.filter(
            (application) =>
              application.name
                .toLowerCase()
                .includes(searchValue.toLowerCase()),
          )

          return filteredApplications.length > 0
            ? { ...workspace, applications: filteredApplications }
            : null
        })
        .filter(Boolean),
    )
  }, [searchValue, workspaces])

  return (
    <div className="flex flex-col bg-gray-50 pl-8 pr-8 pt-2">
      {searchWorkspaces.length > 0 ? (
        searchWorkspaces.map((workspace) => {
          let duplicatingBaseInWorkspace: DuplicatingBaserowApplication = null

          if (duplicatingBase?.workspaceId === workspace.baserowWorkspaceId) {
            duplicatingBaseInWorkspace = duplicatingBase
          }

          return (
            <WorkspaceCard
              key={workspace.id}
              workspace={{
                // Sort applications by last click time
                ...workspace,
                applications: [...workspace.applications].sort((a, b) => {
                  const aLastClicked = cardClickHistoryMap.get(a.id) || ''
                  const bLastClicked = cardClickHistoryMap.get(b.id) || ''
                  return bLastClicked.localeCompare(aLastClicked)
                }),
              }}
              cardClickHistory={cardClickHistory}
              setCardClickHistory={setCardClickHistory}
              onDeleteBase={onDeleteBase}
              onUpdateBaseName={onUpdateBaseName}
              onDuplicateBase={onDuplicateBase}
              duplicatingBase={duplicatingBaseInWorkspace}
              showWorkspaceName={workspaces.length > 1}
            />
          )
        })
      ) : (
        <Empty
          icon={<CircleStackIcon className="mt-16 h-28 w-28 text-gray-400" />}
          title={'No bases found'}
          description={'Try searching for a different base'}
        />
      )}
    </div>
  )
}

export default WorkspacesList
