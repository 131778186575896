import { useEffect, useState } from 'react'

import axios from 'axios'
import { BaserowLogin } from 'types/graphql'

import { useParams } from '@redwoodjs/router'
import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import CenteredLoading from '../Library/Loading/CenteredLoading'

export const QUERY = gql`
  query BaserowLogin {
    loginToBaserowWithTokenCheck {
      jwt
      refreshToken
    }
  }
`

export const Loading = () => <CenteredLoading />

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  loginToBaserowWithTokenCheck: { refreshToken, jwt },
}: CellSuccessProps<BaserowLogin>) => {
  const { linkData, linkType } = useParams()
  const [notificationUrl, setNotificationUrl] = useState('')
  const [isIframeLoading, setIsIframeLoading] = useState(true)

  let baserowUrl = `/login?token=${refreshToken}`
  if (linkType === 'baselink' || linkType === 'form') {
    baserowUrl = `${linkData}?token=${refreshToken}`
  }

  useEffect(() => {
    const getNotificationUrl = async () => {
      const brApi = axios.create({
        baseURL: `${process.env.BASEROW_URL}/api`,
        headers: {
          Authorization: `JWT ${jwt}`,
          'Content-Type': 'application/json',
        },
      })
      const getNotificationResponse = await brApi.patch(
        `/notifications${linkData}/`,
        {
          read: true,
        },
      )
      const notificationData = getNotificationResponse.data.data
      setNotificationUrl(
        `${process.env.BASEROW_URL}/database/${notificationData.database_id}/table/${notificationData.table_id}/row/${notificationData.row_id}?token=${refreshToken}`,
      )
    }
    if (linkType === 'notification') {
      getNotificationUrl()
    }
  }, [linkData, linkType])

  if (linkType === 'notification' && !notificationUrl) {
    return <CenteredLoading />
  }

  if (linkType === 'notification' && notificationUrl) {
    return (
      <>
        {isIframeLoading && <CenteredLoading />}
        <iframe
          id={'baserow-frame'}
          title={'baserow-frame'}
          width={'100%'}
          height={'100%'}
          src={notificationUrl}
          onLoad={() => {
            setIsIframeLoading(false)
          }}
          onError={() => {
            setIsIframeLoading(false)
          }}
        />
      </>
    )
  }

  if (linkType !== 'notification') {
    return (
      <>
        {isIframeLoading && <CenteredLoading />}
        {refreshToken && (
          <iframe
            id={'baserow-frame'}
            title={'baserow-frame'}
            width={'100%'}
            height={'100%'}
            src={`${process.env.BASEROW_URL}${baserowUrl}`}
            onLoad={() => {
              setIsIframeLoading(false)
            }}
            onError={() => {
              setIsIframeLoading(false)
            }}
          />
        )}
      </>
    )
  }
}
