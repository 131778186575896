import { useEffect, useRef, useState, type FC } from 'react'

import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import type { ApplicationPermissions } from 'types/graphql'

import { routes } from '@redwoodjs/router'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import BaseBadge from './BaseBadge'
import BaseCardEditMenu from './BaseCardEditMenu'
import useBasesScreenStore from './BasesScreenStore'
import { baseCardContainerStyle, baseTitleStyle } from './constants'
import {
  CardHistoryTag,
  type BaserowApplication,
  CardClickHistoryItem,
} from './types'

export interface BaseCardProps {
  base: BaserowApplication
  cardClickHistory: CardClickHistoryItem[]
  setCardClickHistory: (cardClickHistory: CardClickHistoryItem[]) => void
  onDeleteBase: (baseId: number) => void
  onUpdateBaseName: (baseId: number, name: string) => void
  applicationPermissions: ApplicationPermissions
  showBaseCardEditMenu: boolean
  onDuplicateBase: (baseId: number) => void
  workspaceId: number
}

const BaseCard: FC<BaseCardProps> = ({
  base,
  cardClickHistory,
  setCardClickHistory,
  onDeleteBase,
  onUpdateBaseName,
  applicationPermissions,
  showBaseCardEditMenu,
  onDuplicateBase,
  workspaceId,
}) => {
  const [
    editBaseId,
    setEditBaseId,
    baseIdRenameMode,
    setBaseStyleEditorAnchor,
    cardsUpdating,
    setBaseIdRenameMode,
  ] = useBasesScreenStore((state) => [
    state.editBaseId,
    state.setEditBaseId,
    state.baseIdRenameMode,
    state.setBaseStyleEditorAnchor,
    state.cardsUpdating,
    state.setBaseIdRenameMode,
  ])
  const [baseName, setBaseName] = useState(base.name)
  const [renameMode, setRenameMode] = useState(false)

  const [cardHistoryTag, setCardHistoryTag] = useState<CardHistoryTag>(
    CardHistoryTag.NEVER_OPENED,
  )

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleBaseClick = () => {
    const upsertItem: CardClickHistoryItem = {
      baseId: base.id,
      clickedAt: dayjs.utc().toISOString(),
    }

    const newCardClickHistory = cardClickHistory.filter(
      (item) => item.baseId !== base.id,
    )

    setCardClickHistory([upsertItem, ...newCardClickHistory])
  }

  const handleBaseEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setEditBaseId(base.id)
    setMenuAnchorEl(event.currentTarget)
  }

  const handleOpenColorPicker = (event: React.MouseEvent<HTMLLIElement>) => {
    setBaseStyleEditorAnchor(menuAnchorEl)
    handleMenuClose(event)
  }

  const handleMenuClose = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setMenuAnchorEl(null)
  }

  useEffect(() => {
    if (base.id) {
      const cardClickHistoryItem = cardClickHistory.find(
        (item) => item.baseId === base.id,
      )

      if (!cardClickHistoryItem) {
        setCardHistoryTag(CardHistoryTag.NEVER_OPENED)
        return
      }

      const clickedAt = dayjs.utc(cardClickHistoryItem.clickedAt).startOf('day')
      const today = dayjs.utc().startOf('day')

      const diffDays = today.diff(clickedAt, 'day')

      if (diffDays === 0) {
        setCardHistoryTag(CardHistoryTag.OPENED_TODAY)
      } else if (diffDays === 1) {
        setCardHistoryTag(CardHistoryTag.OPENED_YESTERDAY)
      } else if (diffDays <= 7) {
        setCardHistoryTag(CardHistoryTag.OPENED_LAST_WEEK)
      } else if (diffDays <= 30) {
        setCardHistoryTag(CardHistoryTag.OPENED_LAST_MONTH)
      } else {
        setCardHistoryTag(CardHistoryTag.NOT_OPENED_RECENTLY)
      }
    }
  }, [cardClickHistory, base.id])

  useEffect(() => {
    if (baseIdRenameMode === base.id) {
      setEditBaseId(base.id)
      setRenameMode(true)
      return
    }

    if (editBaseId !== base.id) {
      setRenameMode(false)
    }
  }, [baseIdRenameMode, editBaseId, base.id, setEditBaseId])

  useEffect(() => {
    if (renameMode && inputRef.current) {
      // if we are renaming a base, focus on the input and select the text
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [renameMode])

  return (
    <a
      className={baseCardContainerStyle}
      onClick={() => {
        // don't annoy the user by navigating them to a base when they mis click when trying to rename a base
        if (cardsUpdating.includes(base.id) || renameMode) return
        handleBaseClick()
      }}
      href={
        base.firstTableId
          ? routes.baseLinkToTable({
              baseId: base.id,
              tableId: base.firstTableId,
            })
          : routes.baseLinkToWorkspace({ workspaceId: workspaceId })
      }
    >
      <div className="flex w-full items-center space-x-2">
        <BaseBadge
          selectedIcon={base?.baserowCardAppearance?.icon}
          iconColor={base?.baserowCardAppearance?.colorName}
          baseId={base.id}
          baseName={base.name}
        />
        <div
          className="flex flex-1 flex-col"
          data-testid={`base-card-title-${base.name}-${base.id}`}
        >
          {renameMode ? (
            <input
              ref={inputRef}
              type="text"
              className="max-h-[24px] rounded border border-gray-300 px-2 py-1 text-sm text-gray-800"
              value={baseName}
              onChange={(e) => setBaseName(e.target.value)}
              onBlur={() => {
                if (baseName !== base.name) {
                  onUpdateBaseName(base.id, baseName)
                }
                setRenameMode(false)
                setBaseIdRenameMode(null)
              }}
              onClick={(e) => {
                // the parent <a> tag is used to navigate to the base
                // we don't want to navigate to the base when we click on the input
                e.stopPropagation()
                e.preventDefault()
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (baseName !== base.name) {
                    onUpdateBaseName(base.id, baseName)
                  }
                  setRenameMode(false)
                  setBaseIdRenameMode(null)
                }
              }}
            />
          ) : (
            <p className={baseTitleStyle}>{base.name}</p>
          )}
          <p className="text-xs text-gray-400">{cardHistoryTag}</p>
        </div>
      </div>
      {showBaseCardEditMenu && (
        <>
          {cardsUpdating.includes(base.id) ? (
            <div className="mr-1 p-2">
              <LoadingSpinner size="x-small" />
            </div>
          ) : (
            <button
              onClick={(e) => handleBaseEditClick(e)}
              className="mr-1 shrink-0 rounded-full p-2 hover:bg-gray-100"
              data-testid={`base-card-edit-button-${base.id}`}
            >
              <EllipsisVerticalIcon className="h-5 w-5 cursor-pointer" />
            </button>
          )}
          <BaseCardEditMenu
            handleMenuClose={handleMenuClose}
            handleOpenColorPicker={handleOpenColorPicker}
            menuAnchorEl={menuAnchorEl}
            onDeleteBase={onDeleteBase}
            onDuplicateBase={onDuplicateBase}
            base={base}
            setRenameMode={setRenameMode}
            applicationPermissions={applicationPermissions}
          />
        </>
      )}
    </a>
  )
}

export default BaseCard
