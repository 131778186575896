import { useState, type FC } from 'react'

import { Popover } from '@mui/material'

import Button from '../Library/Button/Button'

import useBasesScreenStore from './BasesScreenStore'
import type { BaserowWorkspace } from './types'
import WorkspacePicker from './WorkspacePicker'

interface CreateBaseButtonProps {
  workspaces: BaserowWorkspace[]
  onCreateBase: (workspaceId: number) => void
  buttonText?: string
}

const CreateBaseButton: FC<CreateBaseButtonProps> = ({
  workspaces,
  onCreateBase,
  buttonText = 'Create New Base',
}) => {
  const [createBaseLoading] = useBasesScreenStore((state) => [
    state.createBaseLoading,
  ])
  const [workspacePickerAnchor, setWorkspacePickerAnchor] =
    useState<HTMLElement | null>(null)

  return (
    <>
      <Button
        onClick={(e) => {
          if (workspaces.length === 1) {
            onCreateBase(workspaces[0].baserowWorkspaceId)
          } else {
            setWorkspacePickerAnchor(e.currentTarget)
          }
        }}
        loading={createBaseLoading}
        buttonDataTestId="create-base-button"
      >
        {buttonText}
      </Button>
      <Popover
        open={Boolean(workspacePickerAnchor)}
        anchorEl={workspacePickerAnchor}
        onClose={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation()
          e.preventDefault()
          setWorkspacePickerAnchor(null)
        }}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        slotProps={{
          paper: { style: { marginTop: 15 } },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <WorkspacePicker
          workspaces={workspaces}
          onCancel={() => setWorkspacePickerAnchor(null)}
          onCreateBase={(workspaceId) => {
            onCreateBase(workspaceId)
            setWorkspacePickerAnchor(null)
          }}
        />
      </Popover>
    </>
  )
}

export default CreateBaseButton
