import { type FC } from 'react'

import type {
  DuplicatingBaserowApplication,
  ApplicationPermissions,
} from 'types/graphql'

import BaseCard from './BaseCard'
import useBasesScreenStore from './BasesScreenStore'
import DuplicatingBaseCard from './DuplicatingBaseCard'
import type { BaserowWorkspace, CardClickHistoryItem } from './types'

interface WorkspaceCardProps {
  workspace: BaserowWorkspace
  cardClickHistory: CardClickHistoryItem[]
  setCardClickHistory: (cardClickHistory: CardClickHistoryItem[]) => void
  onDeleteBase: (baseId: number) => void
  onUpdateBaseName: (baseId: number, name: string) => void
  onDuplicateBase: (baseId: number) => void
  duplicatingBase: DuplicatingBaserowApplication | null
  showWorkspaceName: boolean
}

const WorkspaceCard: FC<WorkspaceCardProps> = ({
  workspace,
  cardClickHistory,
  setCardClickHistory,
  onDeleteBase,
  onUpdateBaseName,
  onDuplicateBase,
  duplicatingBase,
  showWorkspaceName,
}) => {
  const [canEditCardAppearance] = useBasesScreenStore((state) => [
    state.canEditCardAppearance,
  ])

  let safeBasePermissions: ApplicationPermissions

  if (!workspace.applicationPermissions) {
    const noPermission = {
      default: false,
      exceptions: [],
    }
    safeBasePermissions = {
      createPermission: noPermission,
      updatePermission: noPermission,
      deletePermission: noPermission,
      duplicatePermission: noPermission,
    }
  } else {
    safeBasePermissions = workspace.applicationPermissions
  }

  const { __typename, ...userPermissionFields } = safeBasePermissions

  // define this at the workspace card level so we don't have to repeat ->
  // the computation for each base card
  const showBaseCardEditMenu =
    Object.values(userPermissionFields).some((permission) => permission) ||
    canEditCardAppearance

  return (
    <div className="mb-8">
      {showWorkspaceName && (
        <h1 className="text-gray-500">{workspace.workspaceName}</h1>
      )}
      <div
        className={'mt-3 grid gap-6'}
        style={{
          gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
        }}
      >
        {workspace.applications.map((base) => (
          <BaseCard
            key={base.id}
            base={base}
            cardClickHistory={cardClickHistory}
            setCardClickHistory={setCardClickHistory}
            onDeleteBase={onDeleteBase}
            onUpdateBaseName={onUpdateBaseName}
            applicationPermissions={safeBasePermissions}
            showBaseCardEditMenu={showBaseCardEditMenu}
            onDuplicateBase={onDuplicateBase}
            workspaceId={workspace.baserowWorkspaceId}
          />
        ))}
        {duplicatingBase && (
          <DuplicatingBaseCard
            key={duplicatingBase.originalApplicationId}
            duplicatingBase={duplicatingBase}
          />
        )}
      </div>
    </div>
  )
}

export default WorkspaceCard
