import type { DuplicatingBaserowApplication } from 'types/graphql'
import { create } from 'zustand'

interface BasesScreenStore {
  editBaseId: number | null
  setEditBaseId: (baseId: number) => void
  searchValue: string
  setSearchValue: (searchValue: string) => void
  baseIdRenameMode: number | null
  setBaseIdRenameMode: (baseId: number) => void
  baseStyleEditorAnchor: HTMLElement | null
  setBaseStyleEditorAnchor: (anchor: HTMLElement | null) => void
  cardsUpdating: number[]
  setCardsUpdating: (update: number[] | ((prev: number[]) => number[])) => void
  createBaseLoading: boolean
  setCreateBaseLoading: (createBaseLoading: boolean) => void
  canEditCardAppearance: boolean
  setCanEditCardAppearance: (canEditCardAppearance: boolean) => void
  duplicatingBase: DuplicatingBaserowApplication | null
  setDuplicatingBase: (
    duplicatingBase: DuplicatingBaserowApplication | null,
  ) => void
}

const useBasesScreenStore = create<BasesScreenStore>((set) => ({
  editBaseId: null,
  setEditBaseId: (baseId) => set({ editBaseId: baseId }),
  searchValue: '',
  setSearchValue: (searchValue) => set({ searchValue }),
  baseIdRenameMode: null,
  setBaseIdRenameMode: (baseId) => set({ baseIdRenameMode: baseId }),
  baseStyleEditorAnchor: null,
  setBaseStyleEditorAnchor: (anchor) => set({ baseStyleEditorAnchor: anchor }),
  cardsUpdating: [],
  setCardsUpdating: (update) =>
    set((state) => ({
      cardsUpdating:
        typeof update === 'function' ? update(state.cardsUpdating) : update,
    })),
  createBaseLoading: false,
  setCreateBaseLoading: (createBaseLoading) => set({ createBaseLoading }),
  canEditCardAppearance: false,
  setCanEditCardAppearance: (canEditCardAppearance) =>
    set({ canEditCardAppearance }),
  duplicatingBase: null,
  setDuplicatingBase: (duplicatingBase) => set({ duplicatingBase }),
}))

export default useBasesScreenStore
