import { FLAG_FEATURES } from 'api/src/common/enums'

import { useParams } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import NewBasesScreen from 'src/components/BasesScreen/BasesScreen'
import HubsrowCell from 'src/components/HubsrowCell'
import PageHeader from 'src/components/PageHeader/PageHeader'
import useLiveFeature from 'src/lib/hooks/LiveFeatureFlag/UseLiveFeature'

const HubsrowPage = () => {
  const [newBasesScreenFlag] = useLiveFeature(FLAG_FEATURES.NEW_BASES_SCREEN)
  const { linkData } = useParams()
  return (
    <div className="flex h-screen flex-col">
      <Metadata title="Bases" description="Bases" />
      <PageHeader title="Bases" parentDataTestId="bases-page-title" />
      {newBasesScreenFlag && !linkData ? <NewBasesScreen /> : <HubsrowCell />}
    </div>
  )
}

export default HubsrowPage
