import { type FC } from 'react'

import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { IconButton, InputAdornment, TextField } from '@mui/material'

import useBasesScreenStore from './BasesScreenStore'
import CreateBaseButton from './CreateBaseButton'
import type { BaserowWorkspace } from './types'

interface BasesScreenHeaderProps {
  workspaces: BaserowWorkspace[]
  onCreateBase: (workspaceId: number) => void
}

const BasesScreenHeader: FC<BasesScreenHeaderProps> = ({
  workspaces,
  onCreateBase,
}) => {
  const [searchValue, setSearchValue] = useBasesScreenStore((state) => [
    state.searchValue,
    state.setSearchValue,
  ])
  return (
    <div className="flex items-center justify-between p-8">
      <TextField
        placeholder="Type keywords to search"
        name="searchText"
        label="Search"
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        className="mr-2 max-w-[400px] grow bg-white text-gray-700"
        size="small"
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                {searchValue && (
                  <IconButton
                    className="cursor-pointer hover:bg-transparent"
                    onClick={() => setSearchValue('')}
                  >
                    <XMarkIcon className="h-5 w-5 text-gray-700" />
                  </IconButton>
                )}
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-700" />
              </InputAdornment>
            ),
          },
          inputLabel: { shrink: true },
        }}
      />
      <div className="w-40">
        {workspaces.length > 0 && (
          <CreateBaseButton
            workspaces={workspaces.filter(
              (workspace) =>
                workspace?.applicationPermissions?.createPermission,
            )}
            onCreateBase={onCreateBase}
          />
        )}
      </div>
    </div>
  )
}

export default BasesScreenHeader
