import React, { useState } from 'react'

import { XMarkIcon } from '@heroicons/react/24/solid'
import { Typography } from '@mui/material'
import type { UpsertBaserowCardAppearanceInput } from 'types/graphql'

import ColorGrid from 'src/components/HubDash/CardSettingsDrawer/CardSettingsModules/ColorGrid/ColorGrid'

import FilterNavTabs, { type NavTabs } from '../FilterNavTabs/FilterNavTabs'
import {
  getHexForColor,
  type BaserowColor,
} from '../HubDash/lib/baserow/baserowColors'
import IconPicker from '../IconPicker/IconPicker'
import Button from '../Library/Button/Button'
import IconButton from '../Library/IconButton/IconButton'

import BaseBadge from './BaseBadge'
import useBasesScreenStore from './BasesScreenStore'
import { BaseStyleEditorTab, type BaserowApplication } from './types'
import { generatePseudoRandomDarkerColor } from './utils'

interface BaseStyleEditorProps {
  base: BaserowApplication
  onSave: (input: UpsertBaserowCardAppearanceInput) => void
}

const BaseStyleEditor: React.FC<BaseStyleEditorProps> = ({ base, onSave }) => {
  const [setBaseStyleEditorAnchor] = useBasesScreenStore((state) => [
    state.setBaseStyleEditorAnchor,
  ])
  const [tabSelected, setTabSelected] = useState<BaseStyleEditorTab>(
    BaseStyleEditorTab.COLOUR,
  )
  const colorName =
    base?.baserowCardAppearance?.colorName ||
    generatePseudoRandomDarkerColor(base.id).name

  const [selectedColor, setSelectedColor] = useState<BaserowColor | null>({
    name: colorName,
    hex: getHexForColor(colorName),
  })
  const [selectedIcon, setSelectedIcon] = useState<string>(
    base?.baserowCardAppearance?.icon || '',
  )

  const navTabItems: NavTabs[] = [
    { title: BaseStyleEditorTab.COLOUR },
    {
      title: BaseStyleEditorTab.ICON,
    },
  ]

  const setTabSelectedCallBack = (tabTitle: string) => {
    setTabSelected(tabTitle as BaseStyleEditorTab)
  }

  return (
    <div className="p-4">
      <div className="flex items-center justify-between">
        <Typography>Customise Appearance</Typography>
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => setBaseStyleEditorAnchor(null)}
          aria-label="close"
        >
          <XMarkIcon className="h-5 w-5" />
        </IconButton>
      </div>
      <FilterNavTabs
        navTabs={navTabItems}
        onTabClickCallBack={setTabSelectedCallBack}
        selectedTab={navTabItems.findIndex((tab) => tab.title === tabSelected)}
      />

      <div className="flex h-[300px] w-[392px] flex-col justify-between">
        {tabSelected === BaseStyleEditorTab.COLOUR && (
          <div className="mt-4 flex flex-col justify-between">
            <ColorGrid
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              displayDynamicColors={false}
            />
          </div>
        )}

        {tabSelected === BaseStyleEditorTab.ICON && (
          <div className="mt-4 flex flex-col justify-between gap-4 overflow-scroll">
            <IconPicker
              selectedIcon={selectedIcon}
              setSelectedIcon={setSelectedIcon}
              iconColor={selectedColor?.name}
              colorContrast={true}
            />
          </div>
        )}
        <div className="flex flex-row items-center justify-between p-2">
          <div className="flex flex-row items-center space-x-3">
            <Typography variant="subtitle2" className="text-gray-700">
              Preview:
            </Typography>
            <BaseBadge
              selectedIcon={selectedIcon}
              iconColor={selectedColor?.name}
              baseId={base.id}
              baseName={base.name}
            />
          </div>
          <Button
            onClick={() => {
              onSave({
                applicationId: base.id,
                colorName: selectedColor.name,
                icon: selectedIcon,
              })
              setBaseStyleEditorAnchor(null)
            }}
            className="w-10"
            disabled={
              selectedColor?.name === base?.baserowCardAppearance?.colorName &&
              selectedIcon === base?.baserowCardAppearance?.icon
            }
            buttonDataTestId="base-style-editor-save-button"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BaseStyleEditor
