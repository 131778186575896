import { type FC } from 'react'

import type { DuplicatingBaserowApplication } from 'types/graphql'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import { baseCardContainerStyle, baseTitleStyle } from './constants'

interface DuplicatingBaseCardProps {
  duplicatingBase: DuplicatingBaserowApplication
}

const DuplicatingBaseCard: FC<DuplicatingBaseCardProps> = ({
  duplicatingBase,
}) => {
  return (
    <div className={baseCardContainerStyle} data-testid="duplicating-base-card">
      <div className="flex flex-col">
        <p className={baseTitleStyle}>
          {duplicatingBase.originalApplicationName}
        </p>
        <p className="text-xs text-gray-400">Duplicating</p>
      </div>
      <div className="mr-1 flex shrink-0 items-center space-x-2 p-2">
        <p
          className={'text-sm text-indigo-400'}
        >{`${duplicatingBase.progressPercentage}%`}</p>
        <LoadingSpinner size="x-small" />
      </div>
    </div>
  )
}

export default DuplicatingBaseCard
