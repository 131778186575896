import { CircleStackIcon } from '@heroicons/react/24/outline'
import { Typography } from '@mui/material'

import CreateBaseButton from './CreateBaseButton'
import type { BaserowWorkspace } from './types'

interface EmptyBasesScreenProps {
  noWorkspacesFound: boolean
  workspaces?: BaserowWorkspace[]
  onCreateBase?: (workspaceId: number) => void
}

const EmptyBasesScreen: React.FC<EmptyBasesScreenProps> = ({
  noWorkspacesFound,
  workspaces,
  onCreateBase,
}) => {
  return (
    <div className="flex h-[70vh] items-center justify-center">
      <div className="flex flex-col items-center space-y-8">
        <div className="flex flex-col items-center space-y-3">
          <CircleStackIcon className="h-28 w-28 text-gray-400" />
          <Typography variant="h6">{`No ${noWorkspacesFound ? 'workspaces' : 'bases'} found`}</Typography>
          {noWorkspacesFound || workspaces?.length === 0 ? (
            <Typography variant="body1">
              Please notify Stafflink Support
            </Typography>
          ) : (
            <Typography variant="body1">
              Create one below or reach out to your admin
            </Typography>
          )}
        </div>
        {!noWorkspacesFound && workspaces?.length > 0 && (
          // we can create a new base as long as we have access to at least one workspace
          <CreateBaseButton
            workspaces={workspaces}
            onCreateBase={onCreateBase}
            buttonText="Create Your First Base"
          />
        )}
      </div>
    </div>
  )
}

export default EmptyBasesScreen
