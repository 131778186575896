import { useState } from 'react'

import { TextField, Typography } from '@mui/material'

import AutoComplete from '../Library/Autocomplete/Autocomplete'
import Button from '../Library/Button/Button'

import type { BaserowWorkspace } from './types'

interface WorkspacePickerProps {
  workspaces: BaserowWorkspace[]
  onCancel: () => void
  onCreateBase: (workspaceId: number) => void
}

const WorkspacePicker: React.FC<WorkspacePickerProps> = ({
  workspaces,
  onCancel,
  onCreateBase,
}) => {
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<BaserowWorkspace | null>(null)

  return (
    <div className="min-w-[258px] p-4">
      <Typography>Please select a Workspace</Typography>

      <AutoComplete
        className="mt-4"
        options={workspaces}
        getOptionLabel={(option) => option.workspaceName}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Workspace"
            variant="outlined"
            size="small"
          />
        )}
        onChange={(_, value) => setSelectedWorkspace(value)}
      />
      <div className="mt-8 flex items-center justify-end gap-2">
        <Button className="w-8" variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className="w-8"
          onClick={() => {
            onCreateBase(selectedWorkspace?.baserowWorkspaceId)
          }}
          disabled={!selectedWorkspace}
        >
          Ok
        </Button>
      </div>
    </div>
  )
}

export default WorkspacePicker
