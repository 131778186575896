export const UPSERT_BASE_CARD_APPEARANCE = gql`
  mutation UpsertBaserowCardAppearance(
    $input: UpsertBaserowCardAppearanceInput!
  ) {
    upsertBaserowCardAppearance(input: $input) {
      id
      colorName
      icon
      applicationId
    }
  }
`

export const FIND_WORKSPACE_LIST_QUERY = gql`
  fragment ApplicationPermissionFields on ApplicationPermission {
    default
    exceptions
  }

  query FindWorkspaceListQuery {
    baserowWorkspaces: baserowWorkspacesByClient {
      id
      baserowWorkspaceId
      workspaceName
      applications {
        id
        name
        firstTableId
        baserowCardAppearance {
          id
          colorName
          icon
          applicationId
        }
      }
      applicationPermissions {
        createPermission {
          ...ApplicationPermissionFields
        }
        updatePermission {
          ...ApplicationPermissionFields
        }
        deletePermission {
          ...ApplicationPermissionFields
        }
        duplicatePermission {
          ...ApplicationPermissionFields
        }
      }
    }
    duplicatingBaserowApplication: duplicatingBaserowApplication {
      ...DuplicatingBaserowApplicationFields
    }
  }
`

export const CREATE_BASEROW_BASE = gql`
  mutation CreateBaserowBase($input: CreateBaserowApplicationInput!) {
    createBaserowApplication(input: $input)
  }
`

export const DELETE_BASEROW_BASE = gql`
  mutation DeleteBaserowBase($input: DeleteBaserowApplicationInput!) {
    deleteBaserowApplication(input: $input)
  }
`

export const UPDATE_BASEROW_BASE_NAME = gql`
  mutation UpdateBaserowBaseName($input: UpdateBaserowApplicationNameInput!) {
    updateBaserowApplicationName(input: $input)
  }
`

export const DUPLICATE_BASEROW_BASE = gql`
  mutation DuplicateBaserowBase($input: DuplicateBaserowApplicationInput!) {
    duplicateBaserowApplication(input: $input) {
      ...DuplicatingBaserowApplicationFields
    }
  }
`

export const POLL_DUPLICATING_BASEROW_BASES = gql`
  query PollDuplicatingBaserowBases {
    duplicatingBaserowApplication {
      ...DuplicatingBaserowApplicationFields
    }
  }
`
